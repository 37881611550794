<template lang="pug">
.main-wrapper.especialista-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "especialista" }'> Especialistas</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            Dialog(v-if='model' header='Adicionar Área de Atuação' :visible.sync='dialog.tags' :modal='true' :contentStyle="{'width':'300px'}")
                
                .ta-left.my-2
                    label.form-label Área de Atuação:
                    InputText(type='text' v-model='a_atuacao' placeholder='Área de Atuação' style='width:260px')
    
                .ta-left.my-2
                    label.form-label Odonto:
                    InputSwitch(v-model='a_atuacao_is_odonto' style='margin-top: 2px')
                    p {{a_atuacao_is_odonto }}
            
                .ta-center.my-2
                    Button.p-button-success(v-if='!waitingArea' label='Adicionar' @click='adicionarArea()')
                    ProgressBar(v-if='waitingArea' mode="indeterminate")

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } especialista`")
                        .p-grid.p-fluid
                            .p-col-12.p-md-10
                                .p-grid.p-fluid.p-align-end

                                    .p-col-12.p-md-5(:class="{ 'form-group--error': submitted && $v.model.nm_especialista.$error }")
                                        label.form-label Nome:
                                        InputText(type='text' v-model='$v.model.nm_especialista.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nm_especialista.$error')
                                            .form-message--error(v-if="!$v.model.nm_especialista.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_especialista.maxLength") <b>Nome</b> deve ter no máximo 180 caracteres.
                                            .form-message--error(v-if="!$v.model.nm_especialista.required") <b>Nome</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cpf.$error && !model.ie_plantonista }")
                                        label.form-label CPF:
                                        InputMask(type='tel' v-model='$v.model.nr_cpf.$model' mask='999.999.999-99')
                                        .feedback--errors(v-if='submitted && $v.model.nr_cpf.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.nr_cpf.validateCpf") <b>CPF</b> inválido.

                                    .p-col-6.p-md-4(:class="{ 'form-group--error': submitted && $v.model.dt_nascimento.$error && !model.ie_plantonista }")
                                        label.form-label Data de nascimento:
                                        InputMask(type='tel' v-model='$v.model.dt_nascimento.$model' mask='99/99/9999' placeholder='dd/mm/aaaa')
                                        .feedback--errors(v-if='submitted && $v.model.dt_nascimento.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.dt_nascimento.dateValid") <b>Data de nascimento</b> é inválida.

                                    .p-col-12.p-md-4
                                        label.form-label E-mail:
                                        InputText(type='text' v-model='model.ds_email')

                                    .p-col-6.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error && !model.ie_plantonista}")
                                        label.form-label Telefone:
                                        InputMask(type='tel' v-model='$v.model.nr_telefone.$model' mask='(99) 99999999?9')
                                        .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.nr_telefone.validateFone") <b>Telefone</b> é inválido.

                                    .p-col-12.p-md-4
                                        label.form-label Sexo:
                                        SelectButton.input-sexo(type='text' v-model='model.ie_sexo' :options='options.ie_sexo' optionLabel='text' optionValue='value')

                                    .p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_conselho.$error && !model.ie_plantonista }")
                                        label.form-label Conselho (sigla):
                                        Dropdown(v-model='$v.model.nm_conselho.$model' :options='options.nm_conselho'
                                            optionLabel='text' optionValue='text' placeholder='Selecione')
                                        .feedback--errors(v-if='submitted && $v.model.nm_conselho.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.nm_conselho.required") <b>Nome do conselho</b> é obrigatório.

                                    .p-col-6.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_uf_conselho.$error && !model.ie_plantonista}")
                                        label.form-label UF Conselho:
                                        Dropdown(v-model='$v.model.cd_uf_conselho.$model' :options='options.ufs'
                                            optionLabel='text' optionValue='value' placeholder='Selecione')
                                        .feedback--errors(v-if='submitted && $v.model.cd_uf_conselho.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.cd_uf_conselho.required") <b>UF do conselho</b> é obrigatório.

                                    .p-col-6.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_conselho.$error && !model.ie_plantonista }")
                                        label.form-label Nº do conselho:
                                        InputText(type='text' v-model='$v.model.nr_conselho.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_conselho.$error && !model.ie_plantonista')
                                            .form-message--error(v-if="!$v.model.nr_conselho.required") <b>Número do conselho</b> é obrigatório.

                                    .p-col-12.p-md-5
                                        ProgressBar(v-if='waitingEspecialidades' mode="indeterminate")
                                        div(v-else)
                                            label.form-label Especialidades:
                                            MultiSelect(v-model='model.cd_especialidades' :options='options.especialidades'
                                                optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione' filter)

                                    .p-col-12
                                        label.form-label Observações:
                                        Textarea(v-model='$v.model.ds_observacao.$model' placeholder='(Opcional)' :autoResize="true" rows="5")

                                    .p-col-6
                                        label.form-label Perfil:
                                        Textarea(v-model='$v.model.ds_perfil.$model' placeholder='(Opcional)' :autoResize="true" rows="5")

                                    .p-col-6
                                        label.form-label Formação:
                                        Textarea(v-model='$v.model.ds_formacao.$model' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12.p-md-2
                                .p-grid.p-fluid.p-justify-center

                                    .p-col-8
                                        label.form-label Foto:
                                        canvas#avatarImgSlot
                                        .ta-center.my-4(v-if='waitingAvatar')
                                            ProgressSpinner(strokeWidth='2')
                                        .avatar-wrapper(v-else)
                                            img#avatarImg(:src='model.path_foto')

                                    .p-col-8
                                        FileUpload(name='aq_foto'
                                            chooseLabel='Escolher'
                                            uploadLabel='Enviar'
                                            cancelLabel='Cancelar'
                                            mode='basic'
                                            accept="image/*"
                                            :auto='true'
                                            @before-send='beforeAvatarSend($event)')

                            .p-col-12
                                .p-grid.p-fluid.p-align-end.p-justify-end
                                    .p-col-12
                                        h4.text-box Endereço

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                        label.form-label Estado:
                                        Dropdown(
                                            v-model='$v.model.cd_estado.$model'
                                            :options='options.ufs'
                                            @change='model.cd_cidade = null; getMunicipiosEndereco()'
                                            optionLabel='textAlt'
                                            optionValue='value'
                                            placeholder='Selecione'
                                            filter
                                            )
                                        .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                            .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                        label.form-label Município:
                                        Dropdown(
                                            v-model='$v.model.cd_cidade.$model'
                                            :options='options.municipiosEndereco'
                                            optionLabel='text'
                                            optionValue='value'
                                            placeholder='Selecione'
                                            v-tooltip.bottom="model.cd_estado == null ? 'Informe o estado antes' : ''"
                                            filter
                                            )
                                        .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                            .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                                    .p-col-12.p-md-3
                                        label.form-label Rua:
                                        InputText(type='text' v-model='model.nm_rua' placeholder='(Opcional)')
                                        .form-message--error(v-if="!$v.model.nm_rua.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_rua.maxLength") <b>Rua</b> deve ter no máximo 50 caracteres.
                                    
                                    .p-col-12.p-md-1
                                        label.form-label Número:
                                        InputMask(v-model='model.nr_numero' type='tel' mask='999999' :autoClear='false' :slotChar="''" placeholder='(Opcional)')

                                    .p-col-12.p-md-2
                                        label.form-label Bairro:
                                        InputText(type='text' v-model='model.nm_bairro' placeholder='(Opcional)')
                                        .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_bairro.maxLength") <b>Bairro</b> deve ter no máximo 50 caracteres.
                                    
                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                        label.form-label CEP:
                                        InputMask(type='tel' v-model='$v.model.nr_cep.$model' mask='99999-999' placeholder='(Opcional)')
                                        .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                            .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                            .p-col-12
                                .p-grid.p-fluid.p-align-end.p-justify-start
                                    .p-col-12.p-md-2
                                        label.form-label Áreas de Atuação:
                                        AutoComplete(:multiple="true" v-model="model.cd_tag_area_atuacao" :suggestions="options.filteredAreas" @complete="searchAreaAtuacao($event)" field="ds_area_atuacao")
                                    .p-col-1
                                        Button(
                                            type="button",
                                            icon="jam jam-plus",
                                            style="max-width: 160px",
                                            @click="dialog.tags = true"
                                        )

                            .p-col-12
                                .p-grid.p-fluid.p-align-start.p-justify-start
                                    .p-col-12
                                        h4.text-box Dados Bancários
                                    .p-col-12.p-grid.p-fluid.p-align-start.p-justify-start(v-for="(n_input, index) in model.especialista_banco" :key="index")
                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                            label.form-label Banco:
                                            Dropdown(v-model='n_input.cd_banco'
                                                :id='"banco"+n_input.id'
                                                :options='options.bancos'
                                                optionLabel='nm_banco'
                                                optionValue='id'
                                                placeholder='Selecione'
                                                :filter='options.bancos.length > 0')
                                            .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                                .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Número Conta:
                                            InputText(:id='"conta"+n_input.id' :useGrouping="false" v-model='n_input.nr_conta' :autoClear='false' :slotChar="''" placeholder='Número Conta')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Número Agencia:
                                            InputText(:id='"agencia"+n_input.id' :useGrouping="false" v-model='n_input.nr_agencia_bancaria' :autoClear='false' :slotChar="''" placeholder='Número Agencia')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--err  or(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Operação:
                                            InputText(:id='"operacao"+n_input.id' :useGrouping="false" v-model='n_input.nr_operacao' :autoClear='false' :slotChar="''" placeholder='Operacao')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.

                                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                            label.form-label Chave PIX:
                                            InputText(type='text' v-model='n_input.ds_chave_pix' placeholder='(Opcional)')
                                            .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                                .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.
                                        .p-col-12.p-md-2.btn-remove-item
                                            Button(
                                                type="button",
                                                icon="jam jam-trash",
                                                class="p-button-danger"
                                                style="max-width: 160px; margin-right:5px",
                                                v-tooltip="'Remover Item'"
                                                @click="removeBanco(index)"
                                                v-if="model.especialista_banco.length > 0"
                                            )
                                            Button(
                                                type="button",
                                                icon="jam jam-plus",
                                                style="max-width: 160px",
                                                v-tooltip="'Adicionar Novo Item'"
                                                @click="addBanco(n_input)"
                                                v-if="model.especialista_banco.length === (index+1)"
                                            )

                            .p-col-12
                                .p-grid.p-fluid.p-align-start.p-justify-start
                                    .p-col-12
                                        h4.text-box Dados da empresa médica
                                    .p-col-12.p-grid.p-fluid.p-align-start.p-justify-start
                                        .p-col-12.p-md-4
                                            label.form-label CNPJ:
                                            InputMask(mask='99.999.999/9999-99' v-model='model.nr_cnpj_empresa' placeholder='(Opcional)')
                                        .p-col-12.p-md-8
                                            label.form-label Razão social:
                                            InputText(v-model='model.nm_razao_social_empresa' placeholder='(Opcional)')
                                        .p-col-12
                                            label.form-label Observações:
                                            Textarea(v-model='model.ds_observacao_empresa' placeholder='(Opcional)' :autoResize="true" rows="3")

                            .p-col-12
                                .p-grid.p-fluid.p-align-end.p-justify-end
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.mt-4
                                .p-grid.p-fluid.p-align-end.p-justify-end
                                    .p-col-12.p-md-2
                                        label.form-label Plantonista:
                                        InputSwitch(v-model='model.ie_plantonista')
                                    .p-col-12.p-md-2
                                        label.form-label Habilitado:
                                        InputSwitch(v-model='model.ie_status')
                                    .p-col-12.p-md-2
                                        Button(label='Salvar' type="submit")

</template>

<style lang="scss">
    .p-picklist-source-controls,
    .p-picklist-target-controls {
        display: none;
    }
    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
        width: 45%;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    .p-picklist-buttons-cell {
        text-align: center;
        .p-button {
            display: none !important;
            &:first-of-type, &:nth-of-type(3) {
                display: inline-block !important;
            }
        }
    }
    .p-progress-spinner {
        width: 68.5%;
        height: auto;
    }
    .especialista-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .avatar-wrapper {
            font-size: 0;
            text-align: center;
            margin-top: 10px;
            border-radius: 50%;
            overflow: hidden;
            #avatarImg {
                width: 100%;
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        #avatarImgSlot {
            display: none;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
    .btn-remove-item{
        align-items: flex-end;
        justify-content: flex-start;
        display: flex;
        margin-top: 20px;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import InputSwitch from 'primevue/inputswitch'
    import FileUpload from 'primevue/fileupload'
    import SelectButton from 'primevue/selectbutton'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import Textarea from 'primevue/textarea'
    import AutoComplete from 'primevue/autocomplete'
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputNumber from 'primevue/inputnumber'

    import moment from 'moment'
    import wsConfigs from './../../middleware/configs'
    import {Especialista, Utils, AreaAtuacao, Bancos, DominioValor} from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import { validateCpf, validateFone } from './../../utils'
    import Protocolo from '../../middleware/controllers/Protocolo'
    import { maxLength } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, Dialog, AutoComplete, InputText, Button, InputSwitch, ProgressSpinner,
            InputMask, FileUpload, SelectButton, Dropdown, MultiSelect, Textarea, Tooltip,InputNumber
        },
        directives: { tooltip: Tooltip },
        created () {
            this.getEspecialidades()
            this.getConselhoMedico()
            Utils.getUFs().then(response => {

                this.options.ufs.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200)
                    response.data.forEach(uf => this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado }))

                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                    Especialista.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            keys.forEach(key => this.model[key] = response.data[key])
                            this.model.dt_nascimento = moment(this.model.dt_nascimento).format('DDMMYYYY')
                            this.model.id = id
                            if (this.model.aq_foto.split('/').pop() == 'foto-default.jpeg') {
                                this.model.aq_foto = null
                                this.model.path_foto = require("./../../assets/img/profile-pic-1.jpg")
                            } else {
                                this.model.path_foto = `${ wsConfigs.getMediaUrl() }${ this.model.aq_foto }`

                                this.model.aq_foto = null
                            }

                            this.model.cd_uf_conselho = this.model.cd_uf_conselho?.id
                            this.model.cd_estado = this.options.ufs.find(uf => {
                                return uf.text === this.model.cd_cidade?.ds_uf
                            })?.value || null
                            this.model.cd_cidade = this.model.cd_cidade?.id || null

                            this.model.dt_criado_f = response.data.dt_criado ?
                                moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                            this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : ""

                            if (!this.model.cd_especialidades) this.model.cd_especialidades = []
                            else this.model.cd_especialidades = this.model.cd_especialidades.map(esp => esp.id)

                            if (this.model.especialista_banco.length === 0) this.model.especialista_banco =  [{
                                id: 0,
                                cd_banco: null,
                                nr_conta: null,
                                nr_agencia_bancaria: null,
                                nr_operacao: null,
                                ds_chave_pix: null
                            }]
                            else {
                                this.model.especialista_banco = this.model.especialista_banco.map((ban, i) => {
                                    ban.id = i;
                                    if(ban.cd_banco) ban.cd_banco = ban.cd_banco.id;
                                    return ban
                                })
                                this.model.count_bancos = this.model.especialista_banco.length;
                            }

                            this.getMunicipiosEndereco()
                        }
                        this.waiting = false
                    })
                } else {
                    this.model.path_foto = require("./../../assets/img/profile-pic-1.jpg")
                    this.waiting = false
                }

            })

            this.options.bancos.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
            Bancos.findAll().then(response => {
                if (response.status == 200) {
                    this.options.bancos = response.data
                }
            })
        },
        watch: {
            'model.path_foto': function (val) {
                let check = new Image()
                check.src = val
                this.waitingAvatar = true
                let i = window.setInterval(() => {
                    if (check.complete) {
                        window.clearInterval(i)
                        this.waitingAvatar = false
                    }
                })
            }
        },
        data () {
            return {
                model: {
                    nm_especialista: '',
                    nr_cpf: '',
                    dt_nascimento: '',
                    ie_sexo: 'M',
                    ds_email: '',
                    nr_telefone: '',
                    nm_conselho: '',
                    cd_uf_conselho: null,
                    nr_conselho: '',
                    ds_observacao: '',
                    ds_perfil: '',
                    ds_formacao: '',
                    cd_especialidades: [],
                    cd_estado: null,
                    cd_cidade: null,
                    nm_rua: '',
                    nr_numero: '',
                    nm_bairro: '',
                    nr_cep: '',
                    ie_status: true,
                    ie_plantonista: false,
                    aq_foto: null,
                    path_foto: '',
                    cd_tag_area_atuacao: [],
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                    especialista_banco: [{
                        id: 0,
                        cd_banco: null,
                        nr_conta: null,
                        nr_agencia_bancaria: null,
                        nr_operacao: null,
                        ds_chave_pix: null
                    }],
                    count_bancos:0,
                    nr_cnpj_empresa: '',
                    nm_razao_social_empresa: '',
                    ds_observacao_empresa: '',
                },
                options: {
                    ie_sexo: [
                        { value: 'M', text: 'Masculino' },
                        { value: 'F', text: 'Feminino' }
                    ],
                    nm_conselho: [],
                    ufs: [],
                    municipiosEndereco: [],
                    especialidades: [],
                    filteredAreas: null,
                    bancos: [],
                },
                dialog: {
                    tags: false,
                },
                a_atuacao: '',
                a_atuacao_is_odonto: false,
                waiting: true,
                waitingArea: false,
                waitingForm: false,
                waitingAvatar: false,
                waitingEspecialidades: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nm_especialista: { required, minLength: minLength(2), maxLength: maxLength(180) },
                    nr_cpf: this.model.ie_plantonista ? {} : { validateCpf },
                    dt_nascimento: this.model.ie_plantonista ? {} : { dateValid: val => moment(val, 'DD/MM/YYYY').isValid() },
                    ie_sexo: {},
                    nm_bairro:{minLength: minLength(2), maxLength: maxLength(50) },
                    nm_rua: {minLength: minLength(2), maxLength: maxLength(50) },
                    nr_telefone: this.model.ie_plantonista ? {} : { validateFone },
                    nm_conselho: this.model.ie_plantonista ? {} : { required },
                    cd_uf_conselho: this.model.ie_plantonista ? {} : { required },
                    nr_conselho: this.model.ie_plantonista ? {} : { required },
                    ds_observacao: {},
                    ds_perfil: {},
                    ds_formacao: {},
                    cd_estado: { required },
                    cd_cidade: { required },
                    nr_cep: { minLength: minLength(8) },
                    ie_status: {},
                    aq_foto: {},
                }
            }
            return v
        },
        methods: {
            getConselhoMedico () {
                this.options.nm_conselho
                DominioValor.findAll({ds_mnemonico: 'CONSELHO_MEDICO'}).then((response) => {
					if(response.status === 200) {
						response.data['CONSELHO_MEDICO'].valores.forEach(item => {
							this.options.nm_conselho.push({text: item.ie_valor})
						})
					}
				})
            },
            getMunicipiosEndereco () {
                this.options.municipiosEndereco = []
                if (this.model.cd_estado) {
                    let ufSelected = this.options.ufs.find(uf => uf.value == this.model.cd_estado)
                    Utils.getMunicipiosByUF(ufSelected.text).then(response => {
                        if (response.status === 200) {
                            this.options.municipiosEndereco = response.data.map(mun => {
                                return { value: mun.id, text: mun.nm_cidade }
                            })
                            this.options.municipiosEndereco.unshift({ value: null, text: '- Selecione -' })
                        }
                    })
                }
            },
            adicionarArea() {
                if(!this.a_atuacao) {
                    this.$toast.error('Digite a área de atuação', { duration: 3000 })
                    return;
                }

                this.waitingArea = true;

                AreaAtuacao.save({
                    ds_area_atuacao: this.a_atuacao,
                    ie_tipo_odonto: this.a_atuacao_is_odonto
                    
                    
                    
                }).then(response => {
                    this.waitingArea = false;
                    if(response.status == 200 || response.status == 201) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.a_atuacao = '';
                        this.dialog.tags = false;
                    } else {
                        this.$toast.error('Não foi possível adicionar.', { duration: 3000 })
                    }
                })
            },
            searchAreaAtuacao(event) {

                if(event.query.trim().length) {
                    AreaAtuacao.findAll({ ds_area_atuacao: event.query })
                    .then(response => {
                        this.options.filteredAreas = response.data;
                    })
                }
            },
			toBase64 (file) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				})
			},
            async beforeAvatarSend (ev) {
                this.model.aq_foto = ev.formData.get('aq_foto')

                let avatar = document.getElementById('avatarImg')
                let image = new Image()
                image.src = URL.createObjectURL(this.model.aq_foto)
                image.onload = () => {

                    let slot = document.getElementById('avatarImgSlot')
                    let context = slot.getContext('2d')
                    let cropSize = 0
                    let offset = { x: 0, y: 0 }

                    slot.width = 400
                    slot.height = 400
                    if (image.width > image.height) {
                        cropSize = image.height
                        offset.x = (image.width - image.height) / 2
                    } else {
                        cropSize = image.width
                        offset.y = (image.height - image.width) / 2
                    }

                    context.drawImage(image,
                        offset.x, offset.y, cropSize, cropSize,
                        0, 0, slot.width, slot.height)

                    avatar.src = slot.toDataURL()
					slot.toBlob(async (blob) => {
                        blob.lastModifiedDate = new Date()
                        let a = this.model.aq_foto.name.split('.')
                        a.pop()
                        a = a.join('')
                        this.model.aq_foto = new File([blob], `${ a }.png`)
						this.model.aq_foto = await this.toBase64(this.model.aq_foto).then( response => response)
                    })

                }

                /*
                let usuario = JSON.parse(localStorage.getItem('usuario'))
                let wsConfigs = require('./../../middleware/configs').default
                let cdEstabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id

                ev.formData.append('cd_estabelecimento', cdEstabelecimento)
                if (this.model.id) {
                    ev.formData.append('id', this.model.id)
                    ev.xhr.open('PATCH', `${ wsConfigs.baseUrl }/webcli/especialista/${ this.model.id }/?cd_estabelecimento=${ cdEstabelecimento }`)
                } else ev.xhr.open('POST', `${ wsConfigs.baseUrl }/webcli/especialista/?cd_estabelecimento=${ cdEstabelecimento }`)

                ev.xhr.setRequestHeader('Authorization', `${ usuario.tokenType } ${ usuario.accessToken }`)
                */

                ev.xhr.open('GET', '/')
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.model.especialista_banco.forEach((ban,i)=>{
                    let check_pix = ban.cd_banco != null && ban.ds_chave_pix != null;
                    let check_banco =  ban.cd_banco != null && ban.nr_conta != null && ban.nr_agencia_bancaria != null;
                    if(!check_banco && !check_pix) {
                        this.$delete(this.model.especialista_banco, i)
                    }
                })

                if (this.model.especialista_banco.length === 0) this.model.especialista_banco = [{
                    id: 0,
                    cd_banco: null,
                    nr_conta: null,
                    nr_agencia_bancaria: null,
                    nr_operacao: null,
                    ds_chave_pix: null
                }]

                if(this.model.ie_plantonista) this.model.dt_nascimento = '01/01/1990'

                let dataSend = Object.assign({}, this.model)

                dataSend.dt_nascimento = moment(dataSend.dt_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
                let arrConvertOnlyNumbers = ['nr_cpf', 'nr_telefone', 'nr_cep', 'nr_cnpj_empresa']
                arrConvertOnlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

                dataSend['cd_tag_area_atuacao'] = this.model.cd_tag_area_atuacao.map(area => area.id)

                if (dataSend.aq_foto == null) delete dataSend.aq_foto
                // else
                //     dataSend.especialista_banco = JSON.stringify(dataSend.especialista_banco)

                this.waitingForm = true
                Especialista.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        if (! dataSend.id) this.$router.push({ name: 'especialista' })
                        else if(dataSend.aq_foto) {
                            this.model.path_foto = `${ wsConfigs.getMediaUrl() }${ response.data.aq_foto }`
                            this.model.aq_foto = null
                        }
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            getEspecialidades() {
                this.waitingEspecialidades = true
                Protocolo.getEspecialidades().then(response => {
                    this.waitingEspecialidades = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.options.especialidades = response.data.map(esp => {
                            return {text: esp.nm_especialidade, value: esp.id}
                        })
                    }
                })
            },
            addBanco(list){
                let check_pix = list.cd_banco != null && list.ds_chave_pix != null;
                let check_banco =  list.cd_banco != null && list.nr_conta != null && list.nr_agencia_bancaria != null;
                if(check_pix || check_banco) {
                    this.model.especialista_banco.push({
                        id: ++this.model.count_bancos,
                        cd_banco: null,
                        nr_conta: null,
                        nr_agencia_bancaria: null,
                        nr_operacao: null,
                        ds_chave_pix: null
                    })
                }
            },
            removeBanco(index){
                if(index > 0) {
                    this.$delete(this.model.especialista_banco,index)
                } else {
                    if(this.model.especialista_banco.length > 1)
                        this.$delete(this.model.especialista_banco,index)
                    else {
                        this.model.especialista_banco = [{
                            id: 0,
                            cd_banco: null,
                            nr_conta: null,
                            nr_agencia_bancaria: null,
                            nr_operacao: null,
                            ds_chave_pix: null
                        }]
                    }
                }
            }
        }
    }
</script>
